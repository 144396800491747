export default function Header()
{
    return(
<div className="App bg-teal-700 " > 


 <h1 className="text-3xl font-bold underline text-cyan-300 animate__animated animate__bounceInLeft inline-flex">
      Hello Folks!  

   
    </h1>
    
    
    </div>

    );
}