import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './LandingPage/Header';
import Content from './LandingPage/Content';
import 'animate.css';
import Footer from './LandingPage/Footer';
function App() {
  return (
    <div className='text-white'>
     <Header/>
     <Content/>
     <Footer/>
    </div>
  );
}

export default App;
