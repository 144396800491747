import Card from "./Cards";
import  Vs from "../Asset/vse.png"
import  Ms from "../Asset/mse.png"
export default function Content()
{


    return(
        <div >

<h1 className="text-3xl flex justify-center mt-10">Play store Apps</h1>

            <div className="text-white flex flex-row justify-around">
            <Card Name='Video Search Engine' Image={Vs} Year={2023} content='An all-in-one video search app consolidates multiple video search engines into a single platform, enabling users to search for video content across various sources simultaneously. By entering a search query, the app retrieves video results from different video search engines, eliminating the need to visit each platform individually. This not only saves time but also provides a user-friendly and easily navigable interface for browsing and accessing video content from multiple sources within a single app'/>

<Card Name='Multi Search Engine' Image={Ms} Year={2023} content='This app offers a distinctive feature—an intuitive single search bar. Users can input keywords and, upon pressing enter, the app swiftly fetches multiple answers from diverse websites. Notably, this app sets itself apart by prioritizing user privacy, as it operates without utilizing any user cookies, data, or necessitating login credentials.

'/>


            </div>



        </div>
    );
}