interface Name{
Name:string;
Image:string;
Year:number;
content:string



}
export default function Card(props:Name)
{
    return(
<div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-16 p-3">
    <a href="#">
        <img className="rounded-t-lg" src="" alt="" />
        {/* <img src="../Asset/vse.png"></img> */}
        <img className="rounded-t-lg ml-32" src={props.Image} width={100}  />

    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{props.Name} ({props.Year})</h5>
        </a>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{props.content}</p>
        <a href="#" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Read more
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            </svg>
        </a>
    </div>
</div>

    );
}